<template>
    <b-modal id="modal-receipt-template-remark" centered size="md" title="收據可用標籤">
        <b-card no-body class="mb-0">
            <p>
                在收據模板中，您可以使用下列標籤來標記需要填入的內容。當您生成收據時，這些標籤將被系統自動替換為相應的數據：
            </p>
            <b-table :items="data" :fields="tableColumns" class="table table-bordered table-hover table-sm"
                thead-class="thead-light">
                <template #cell(tag)="data">
                    <code>{{ data.value }}</code>
                </template>


            </b-table>
        </b-card>
    </b-modal>
</template>
<script>
import {
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BModal,
        BCard,
        BRow,
        BCol,
        BTable,
        ToastificationContent
    },
    data() {
        return {
            tableColumns: [
                { key: 'tag', label: '標籤' },
                { key: 'description', label: '替換內容' },
            ],
            data: [
                { tag: '${receipt_id}', description: '收據號碼'},
                { tag: '${contract_code}', description: '合約編號'},
                { tag: '${invoice_number}', description: '發票編號'},
                { tag: '${project_id}', description: '項目編號'},
                { tag: '${audit_date}', description: '收據日期/核數日期'}, 
                { tag: '${payment_date}', description: '付款日期'},
                { tag: '${payment_method}', description: '付款方法'},
                { tag: '${payment_amount}', description: '付款金額'},
                { tag: '${client_contact_name}', description: '收據收件人'},
                { tag: '${client_company_name_cn}', description: '收件人公司名稱(中文)'},
                { tag: '${client_company_name_en}', description: '收件人公司名稱(英文)'},
                { tag: '${client_address}', description: '收件人地址'},
                { tag: '${client_phone}', description: '收件人地址'},
                { tag: '${client_email}', description: '收件人電郵'},
                //Invoice item
                { tag: '${item_index}', description: '商品或服務序號'},
                { tag: '${item_name}', description: '商品或服務名稱'},
                { tag: '${item_description}', description: '商品或服務的詳細描述或說明'},
                { tag: '${item_quantity}', description: '商品或服務的數目'},
                { tag: '${item_amount}', description: '商品或服務單價，表示每單位的價格'},
                { tag: '${item_total}', description: '商品或服務總金額'},
                //Payment
                { tag: '${p_date}', description: '歷史付款日期'},
                { tag: '${p_method}', description: '歷史付款方式'},
                { tag: '${p_amount}', description: '歷史付款金額'},
                { tag: '${total_amount}', description: '總計金額'},
                { tag: '${total_paid}', description: '已付金額'},
                { tag: '${total_remain}', description: '剩餘金額'}   
            ]
        };
    },
    methods: {
        doCopy(valueToCopy) {
            console.log(valueToCopy);
            this.$copyText(valueToCopy).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `已複制 ${valueToCopy} 到剪貼板`,
                        icon: 'BellIcon',
                    },
                });
            }, e => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Cannot copy!',
                        icon: 'BellIcon',
                    },
                });
            });
        },
    }
}


</script>
